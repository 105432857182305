body {
  margin: 0;
  line-height: normal;
}

:root {
  /* fonts */
  --font-montserrat: Montserrat;

  /* font sizes */
  --font-size-smi: 13px;
  --font-size-lg: 18px;
  --font-size-5xl: 24px;
  --font-size-xl: 20px;
  --font-size-9xl: 28px;
  --font-size-base: 16px;
  --font-size-29xl: 48px;
  --font-size-2xs: 11px;
  --font-size-33xl: 52px;
  --font-size-13xl: 32px;

  /* Colors */
  --color-springgreen-100: #55fe83;
  --color-springgreen-200: #55ff76;
  --color-springgreen-300: rgba(85, 255, 118, 0.97);
  --color-white: #fff;
  --color-gray-100: #222129;
  --color-gray-200: #17161b;
  --color-gray-300: rgba(255, 255, 255, 0.97);
  --color-deepskyblue-100: #55b7ff;
  --color-tomato: rgba(255, 85, 85, 0.2);
  --color-darkslategray: #383743;
  --color-skyblue-100: rgba(116, 218, 255, 0.97);
  --color-mediumspringgreen: #57ff98;
  --color-darkslateblue: #284a83;

  /* Paddings */
  --padding-mid: clamp(12px, 0.8vw, 17px );
  --padding-22xl: clamp(24px, 2vw, 41px );
  --padding-base: clamp(12px, 0.8vw, 17px );

  /* border radiuses */
  --br-3xs: 10px;
  --br-12xs: 1px;
  --br-10xs: 3px;
  --br-mini: 15px;
  --br-81xl: 100px;
  --br-34xl: 53px;
  --br-11xl: 30px;
  --br-981xl: 1000px;
  --br-2xl: 21px;
}
